<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                    <ValidationProvider name="Crop Type" vid="crop_type_id" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="crop_type_id"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('seedsConfig.cropType')}} <span class="text-danger">*</span>
                        </template>
                         <b-form-select
                        plain
                        v-model="cropNameData.crop_type_id"
                        :options="CropTypeList"
                        id="crop_type_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Sub Type" vid="sub_type_id">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="sub_type_id"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('seedsConfig.sub_type')}}
                        </template>
                        <b-form-select
                        plain
                        v-model="cropNameData.sub_type_id"
                        :options="subTypeList"
                        id="sub_type_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Crop Name" vid="crop_name" rules="required">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="crop_name"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('seedsConfig.cropName')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="crop_name"
                          v-model="cropNameData.crop_name"
                          :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Crop Name (Bn)" vid="crop_name_bn" rules="required">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="crop_name_bn"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('seedsConfig.cropNameBn')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="crop_name_bn"
                          v-model="cropNameData.crop_name_bn"
                          :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Scientific Name (En)" vid="scientific_name">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="scientific_name"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('germConfig.scientific_name_en')}} <span class="text-danger"></span>
                        </template>
                        <b-form-input
                          id="scientific_name"
                          v-model="cropNameData.scientific_name"
                          :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Scientific Name (Bn)" vid="scientific_name_bn">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="scientific_name_bn"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('germConfig.scientific_name_bn')}} <span class="text-danger"></span>
                        </template>
                        <b-form-input
                          id="scientific_name_bn"
                          v-model="cropNameData.scientific_name_bn"
                          :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="English Name (En)" vid="english_name">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="english_name"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('germConfig.english_name_en')}} <span class="text-danger"></span>
                        </template>
                        <b-form-input
                          id="english_name"
                          v-model="cropNameData.english_name"
                          :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="English Name (Bn)" vid="english_name_bn">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="english_name_bn"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('germConfig.english_name_bn')}} <span class="text-danger"></span>
                        </template>
                        <b-form-input
                          id="english_name_bn"
                          v-model="cropNameData.english_name_bn"
                          :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Category" vid="category_id">
                        <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="category_id"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        {{ $t('seedsConfig.category') }}
                        </template>
                        <b-form-checkbox-group
                            v-model="cropNameData.category_id"
                            :options="categoryList"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-checkbox-group>
                        <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Production Season" vid="production_season_id" rules="required">
                        <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="production_season_id"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        {{ $t('seedsConfig.productionSeason') }} <span class="text-danger">*</span>
                        </template>
                        <b-form-checkbox-group
                            v-model="cropNameData.production_season_id"
                            :options="productionSeasonList"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-checkbox-group>
                        <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                        </b-form-group>
                    </ValidationProvider>
                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { cropNameStore, cropNameUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getCropNameData()
      const arr = []
      tmp.crop_categories.forEach(item => {
        arr.push(item.category_id)
      })
      const arr2 = []
      tmp.crop_seasons.forEach(item1 => {
        arr2.push(item1.production_season_id)
      })
      this.cropNameData = Object.assign({}, this.cropNameData, tmp, { category_id: arr }, { production_season_id: arr2 })
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      cropNameData: {
        id: '',
        crop_name: '',
        crop_name_bn: '',
        english_name: '',
        english_name_bn: '',
        scientific_name: '',
        scientific_name_bn: '',
        crop_type_id: 0,
        type_name: '',
        type_name_bn: '',
        status: 1,
        sub_type_id: 0,
        production_season_id: [],
        category_id: []
      }
    }
  },
  computed: {
    CropTypeList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.CropTypeList.filter(item => item.status === 1)
    },
    categoryList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.seedCategoryList.filter(item => item.status === 1)
    },
    productionSeasonList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.productionSeasonList.filter(item => item.status === 1)
    },
    subTypeList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.seedSubTypeList.filter(item => item.status === 1)
    }
  },
  methods: {
    getCropNameData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async createData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      if (this.cropNameData.id) {
        result = await RestApi.putData(seedFertilizerServiceBaseUrl, `${cropNameUpdate}/${this.id}`, this.cropNameData)
      } else {
        result = await RestApi.postData(seedFertilizerServiceBaseUrl, cropNameStore, this.cropNameData)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('SeedsFertilizer/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    }
  }
}
</script>
